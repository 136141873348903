<template>
  <div class="share-platforms" :class="{ 'share-platforms--visible': visible }">
    <a
      class="share-platforms__link"
      href="https://www.facebook.com/browngrottaarts/"
      target="_blank"
    >
      <IconFacebook class="share-platforms__link-svg" />
    </a>
    <!-- <a
      class="share-platforms__link"
      href="https://www.artsper.com/us/art-galleries/united-states/7707/browngrotta-arts"
      target="_blank"
    >
      <IconArtsper class="share-platforms__link-svg" />
    </a> -->
    <a
      class="share-platforms__link"
      href="https://www.1stdibs.com/dealers/browngrotta-arts/"
      target="_blank"
    >
      <Icon1stDibs class="share-platforms__link-svg" />
    </a>
    <a
      class="share-platforms__link"
      href="https://www.instagram.com/browngrottaarts/"
      target="_blank"
    >
      <IconInstagram class="share-platforms__link-svg" />
    </a>
    <a class="share-platforms__link" href="https://twitter.com/BrowngrottaArts" target="_blank">
      <IconTwitter class="share-platforms__link-svg" />
    </a>
    <a
      class="share-platforms__link"
      href="https://www.artsy.net/partner/browngrotta-arts"
      target="_blank"
    >
      <IconArtsy class="share-platforms__link-svg" />
    </a>
    <a
      class="share-platforms__link"
      href="https://www.youtube.com/channel/UCV7IJwPD34cF-U88hB5e4aw"
      target="_blank"
    >
      <IconYoutube class="share-platforms__link-svg" />
    </a>
    <a class="share-platforms__link" href="https://arttextstyle.com/" target="_blank">
      <IconArttextstyle class="share-platforms__link-svg" />
    </a>
    <IconCommunities class="share-platforms__communities" @click.native="visible = !visible" />
  </div>
</template>

<script>
import IconYoutube from "@/components/icons/IconYoutube.vue";
import IconTwitter from "@/components/icons/IconTwitter.vue";
import IconInstagram from "@/components/icons/IconInstagram.vue";
import IconFacebook from "@/components/icons/IconFacebook.vue";
import IconArtsy from "@/components/icons/IconArtsy.vue";
import IconArtsper from "@/components/icons/IconArtsper.vue";
import Icon1stDibs from "@/components/icons/Icon1stDibs.vue";
import IconCommunities from "@/components/icons/IconCommunities.vue";
import IconArttextstyle from "@/components/icons/IconArttextstyleCircle.vue";

export default {
  components: {
    IconYoutube,
    IconTwitter,
    IconInstagram,
    IconFacebook,
    IconArtsy,
    IconArtsper,
    Icon1stDibs,
    IconCommunities,
    IconArttextstyle,
  },
  data() {
    return {
      visible: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.share-platforms {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  @media screen and (max-width: $xxs) {
    padding: 7px;
    border-radius: 25px;
    border: 0.6px solid $white;
    background-color: rgba($color: $white, $alpha: 0.2);
    width: 50px;
    max-height: 50px;
    overflow: hidden;
    transition: all 0.3s ease;
    gap: 5px;
    &--visible {
      max-height: 390px;
      background-color: rgba($color: $black, $alpha: 0.32);
    }
  }
  &__link {
    width: 4.6rem;
    height: 4.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba($color: $white, $alpha: 0.2);
    border-radius: 50%;
    border: 0.6px solid $white;
    padding: 10px;
    flex-shrink: 0;
    transition: all 0.3s ease-in-out;
    @media screen and (min-width: $xxxl) {
      width: 4.2rem;
      height: 4.2rem;
    }
    @media screen and (max-width: $xl) {
      width: 4rem;
      height: 4rem;
    }
    @media screen and (max-width: $xxs) {
      width: 3.6rem;
      height: 3.6rem;
      padding: 7px;
    }
    @media screen and (max-width: $xxxxxs) {
      width: 3.4rem;
      height: 3.4rem;
    }
    &-svg {
      height: 100%;
      max-width: 100%;
      width: auto;
      ::v-deep path {
        transition: all 0.3s ease-in-out;
      }
    }
    &:hover {
      border-color: rgba(126, 198, 255, 1);
      background: rgba(126, 198, 255, 0.2);
      ::v-deep svg path {
        fill: rgba(126, 198, 255, 1);
      }
    }
  }
  &__communities {
    display: none;
    cursor: pointer;
    margin-top: 10px;
    @media screen and (max-width: $sm) {
      margin: 3.5px;
    }
    @media screen and (max-width: $xxs) {
      display: block;
    }
  }
}
</style>

