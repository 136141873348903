var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"home"},[_c('Loader',{attrs:{"loading":_vm.loading}}),_c('div',{staticClass:"home__header"},[_c('div',{staticClass:"home__header-container",style:({
        background: `linear-gradient(0deg, rgba(0, 0, 0, 0.26) 0%, rgba(0, 0, 0, 0.26) 100%), radial-gradient(105.32% 44.51% at 67.01% 50%, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.56) 100%), url(${
          _vm.featuredExhibition.slug ? _vm.featuredExhibition.home_page_image : _vm.home_page_image
        }),  lightgray`,
        backgroundSize: 'cover',
        backgroundPosition: '15%',
      })},[_c('div',{staticClass:"home__header-container-content"},[(_vm.featuredExhibition.slug != null)?_c('p',{staticClass:"home__header-sub-title text-sub-title"},[_vm._v(" "+_vm._s(_vm.featuredExhibition.dates)+" | "+_vm._s(_vm.featuredExhibition.exhibition_type)+" ")]):_vm._e(),_c('h2',{staticClass:"home__header-title text-title"},[_vm._v(" "+_vm._s(_vm.featuredExhibition.slug ? _vm.featuredExhibition.title : "browngrotta arts: international art textiles, mixed media and dimensional art")+" ")]),(_vm.featuredExhibition.slug && _vm.featuredExhibition.home_description)?_c('div',{staticClass:"collect-editor collect-editor--white home__header-description",domProps:{"innerHTML":_vm._s(_vm.featuredExhibition.home_description)}}):_vm._e(),_c('div',{staticClass:"home__header-buttons"},[(_vm.featuredExhibition.slug)?_c('BaseButton',{staticClass:"home__header-button button--white",attrs:{"text":"PLAN YOUR VISIT"},on:{"click":function($event){return _vm.$router.push({ path: `/exhibitions/${_vm.featuredExhibition.slug}` })}}}):_c('BaseButton',{staticClass:"home__header-button button--white",attrs:{"text":"ABOUT GALLERY"},on:{"click":function($event){return _vm.$router.push({ path: '/about' })}}}),_c('BaseButton',{staticClass:"home__header-button button--outline-white",attrs:{"text":"ALL EXHIBITIONS"},on:{"click":function($event){return _vm.$router.push({ path: '/exhibitions' })}}})],1),_c('div',{staticClass:"mobile-copyright"},[_c('Copyright',{staticClass:"home__header-copyright"},[_vm._v(" "+_vm._s(_vm.featuredExhibition.copyright ? _vm.featuredExhibition.copyright : "Tom Grotta")+" ")]),_c('SharePlatforms',{staticClass:"home__share-platforms home__share-platforms--mobile"})],1)]),_c('SharePlatforms',{staticClass:"home__share-platforms"})],1)]),_c('div',{staticClass:"home__artworks"},_vm._l((_vm.artworks),function(artwork,key){return _c('div',{key:`artwork-${key}`,staticClass:"home__artwork"},[_c('div',{staticClass:"home__artwork-image",style:({
          backgroundImage: `url(${artwork.search_image})`,
        }),attrs:{"alt":artwork.title}}),_c('div',{staticClass:"home__artwork-container",on:{"click":function($event){return _vm.$router.push({ path: `/artworks/${artwork.slug}` })}}},[_c('p',{staticClass:"home__artwork-text text-sub-title"},[_vm._v("Click to View")]),_c('div',{staticClass:"home__artwork-text-mobile"},[_c('h3',{staticClass:"home__artwork-title text-title"},[_vm._v(_vm._s(artwork.title))]),_c('div',{staticClass:"home__artwork-sub-title"},[_c('p',{staticClass:"home__artwork-sub-title--text"},[_vm._v(" "+_vm._s(artwork.artist)+" ")])])])])])}),0),_c('InfiniteLoading',{staticClass:"infinite-loading",on:{"infinite":_vm.loadMore}}),(_vm.hasMorePages)?_c('div',{staticClass:"home__footer"},[_c('BaseButton',{staticClass:"button--white",attrs:{"text":"LOAD MORE ARTWORKS"},on:{"click":_vm.loadMore}})],1):_vm._e(),_c('div',{staticStyle:{"padding":"15px 0"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }