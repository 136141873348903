<template>
  <transition name="loader__fade">
    <div class="loader" :class="{ 'loader--finish': percent == 100 }">
      <img class="loader__image" src="@/assets/images/loader_background.jpg" alt="art" />
      <div class="loader__container">
        <Logo class="loader__logo"></Logo>
        <p class="loader__text text-base">
          International art textiles, fiber sculpture, ceramics, and mixed media
        </p>
        <div class="loader__progres">
          <div
            class="loader__progres-dote"
            v-for="item in 6"
            :key="item"
            :class="{ 'loader__progres-dote--active': item * 16 <= percent }"
          ></div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Logo from "@/components/icons/Logo.vue";
export default {
  components: {
    Logo,
  },
  props: {
    loading: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      percent: 0,
    };
  },
  beforeDestroy() {
    clearInterval(this.timerId);
  },
  watch: {
    loading: {
      handler: function (newVal) {
        if (newVal) {
          this.percent = 0;
          this.timerId = setInterval(() => {
            if (this.percent <= 82) this.percent += 1;
            else clearInterval(this.timerId);
          }, 55);
        } else {
          clearInterval(this.timerId);
          this.percent = 100;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.loader {
  position: fixed;
  top: 0;
  left: 0;
  background-color: $lightBlack;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: 1000;
  &__fade {
    &-leave-active {
      animation: loadFinish 1s ease-in 0.8s forwards;
    }
  }
  &--finish {
    animation: loadFinish 1s ease-in 0.8s forwards;
  }
  &__image {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    @media (max-width: $xxs) {
      width: auto;
      max-width: none;
    }
  }
  &__container {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    background-color: rgba($color: $lightBlack, $alpha: 0.8);
    padding-bottom: 8rem;
    @media screen and (max-width: $xxs) {
      padding: 0 25px 10rem;
    }
  }
  &__logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &__text {
    font-size: 2.4rem;
    line-height: 1.1;
    letter-spacing: -1px;
    text-align: center;
    @media screen and (max-width: $xxs) {
      font-size: 2.6rem;
      line-height: 1.4;
      letter-spacing: -1.08px;
    }
  }
  &__progres {
    display: flex;
    align-items: center;
    margin-top: 7rem;
    @media screen and (max-width: $xxs) {
      margin-top: 6rem;
    }
    &-dote {
      height: 7px;
      width: 7px;
      border-radius: 50%;
      background-color: rgba($color: $white, $alpha: 0.4);
      transition: all 0.5s linear;
      margin: 0 17px;
      &--active {
        background-color: $blue;
        box-shadow: 0 0 8px $blue;
      }
    }
  }
}
@keyframes loadFinish {
  from {
    left: 0;
    opacity: 1;
  }
  to {
    left: -100%;
    opacity: 0.1;
  }
}
</style>
